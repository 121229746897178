import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Logo from "../images/freelogo.png"
import TwitterIcon from "../images/twitter.png"
import InstagramIcon from "../images/instagramicon.png"

class BigFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <MainContainer>
        <Center>
          <LeftBox>
            <Link
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              to="/"
            >
              <LogoContainer alt="Free" src={Logo} />
              <FooterText>HowToGetFreeCrypto.com</FooterText>
            </Link>
            <FooterDisc>
              This site is a free service for you to learn how to get free
              crypto. We are not involved with these websites in any way. We
              will do our best to only list legit giveaways, but are not
              responsible for any issues or loss. Trading and investing in
              cryptocurrencies involves substantial risk and is not suitable for
              everyone. Please do your research and make sure you never share
              your private keys with anyone!
            </FooterDisc>
          </LeftBox>
          <LeftBox>
            <FooterTitle>Donate</FooterTitle>
            <AddressRow>
              <TickerText>BTC: </TickerText>
              <AddressText>3DbdMp2W2mKQgiBLCwf2NVSoG8HzoaE7hN</AddressText>
            </AddressRow>
            <AddressRow>
              <TickerText>ETH: </TickerText>
              <AddressText>
                0xb7DA94ef030f91b497B38c474fA2E25c96C27E45
              </AddressText>
            </AddressRow>
            <AddressRow>
              <TickerText>LTC: </TickerText>
              <AddressText>MWErjZQNBMtirf17ti3MhDjQb16jEuEjYn</AddressText>
            </AddressRow>
            <FooterTitle>Social Links</FooterTitle>
            <SocialRow>
              <SocialIcon
                onClick={() =>
                  window.open("https://twitter.com/HTGFreeCrypto", "_blank")
                }
                alt="Twitter"
                src={TwitterIcon}
              />
              <SocialIcon
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/howtogetfreecrypto/",
                    "_blank"
                  )
                }
                alt="Instagram"
                src={InstagramIcon}
              />
            </SocialRow>
          </LeftBox>
        </Center>
      </MainContainer>
    )
  }
}

export default BigFooter

const MainContainer = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(17, 62, 180, 1),
    rgba(22, 82, 240, 1)
  );
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Center = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 80px;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    flex-direction: column;
    width: 90%;
    padding-bottom: 40px;
  }
`

const LeftBox = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 100%;
  }
`

const FooterText = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-shadow: 0 1px 24px rgba(0, 0, 0, 0.29);
  font-size: 22px;
  // line-height: 48px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  margin-left: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 18px;
  }
`

const AddressRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
`

const TickerText = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-shadow: 0 1px 24px rgba(0, 0, 0, 0.29);
  font-size: 18px;
  // line-height: 48px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  margin-left: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const AddressText = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
  }
`

const FooterTitle = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-shadow: 0 1px 24px rgba(0, 0, 0, 0.29);
  font-size: 22px;
  // line-height: 48px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  margin-left: 10px;
  margin-top: 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.9);
  padding-bottom: 4px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 18px;
    margin-left: 0px;
    margin-top: 20px;
  }
`

const FooterDisc = styled.span`
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin-top: 20px;
  align-text: left;
  margin-left: 50px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    margin-left: 0px;
`

const LogoContainer = styled.img`
  width: 40px;
  height: 40px;
  margin: 0px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 30px;
    height: 30px;
  }
`

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
`

const SocialIcon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 10px;

  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 30px;
    height: 30px;
  }
`
