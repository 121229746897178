import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Logo from "../images/freelogo.png"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <MainContainer>
        <TopContainer>
          <Link
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            to="/"
          >
            <LogoContainer alt="Free" src={Logo} />
            <HeaderText>HowToGetFreeCrypto.com</HeaderText>
          </Link>
          <LinksRow>
            <Link to="/">
              <LinkText>Home</LinkText>
            </Link>
            <Link to="/#crypto">
              <LinkText>Crypto</LinkText>
            </Link>
          </LinksRow>
        </TopContainer>
      </MainContainer>
    )
  }
}

export default Header

const MainContainer = styled.div`
  background-color: rgba(17, 62, 180, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const LogoContainer = styled.img`
  width: 40px;
  height: 40px;
  margin: 0px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 30px;
    height: 30px;
  }
`

const TopContainer = styled.div`
  // background-color: rgba(22, 82, 240, 1);
  // background: linear-gradient(
  //   to right bottom,
  //   rgba(255, 69, 0, 1),
  //   rgba(255, 69, 0, 0.8)
  // );
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    margin: 0px;
    width: 100%;
    justify-content: flex-start;
  }
`

const LinksRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    display: none;
  }
`

const HeaderText = styled.h1`
  text-shadow: 0 1px 24px rgba(0, 0, 0, 0.29);
  font-size: 22px;
  // line-height: 48px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  margin-left: 10px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 18px;
  }
`

const LinkText = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-right: 10px;
`
